$rubik:'Rubik', sans-serif;
$raleway:'Raleway', sans-serif;

$orange:#ffb400;
$blue:#03498b;
$grey:#f6f9fa;
$white:#ffffff;
$green:#aad81d;
$yellow:#f4da00;
$font_color:#425b74;

@mixin fill{
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

@mixin gradient{
background: rgba(178,216,238,1);
background: -moz-linear-gradient(-45deg, rgba(178,216,238,1) 0%, rgba(178,216,238,1) 33%, rgba(221,234,240,1) 88%, rgba(221,234,240,1) 100%);
background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(178,216,238,1)), color-stop(33%, rgba(178,216,238,1)), color-stop(88%, rgba(221,234,240,1)), color-stop(100%, rgba(221,234,240,1)));
background: -webkit-linear-gradient(-45deg, rgba(178,216,238,1) 0%, rgba(178,216,238,1) 33%, rgba(221,234,240,1) 88%, rgba(221,234,240,1) 100%);
background: -o-linear-gradient(-45deg, rgba(178,216,238,1) 0%, rgba(178,216,238,1) 33%, rgba(221,234,240,1) 88%, rgba(221,234,240,1) 100%);
background: -ms-linear-gradient(-45deg, rgba(178,216,238,1) 0%, rgba(178,216,238,1) 33%, rgba(221,234,240,1) 88%, rgba(221,234,240,1) 100%);
background: linear-gradient(135deg, rgba(178,216,238,1) 0%, rgba(178,216,238,1) 33%, rgba(221,234,240,1) 88%, rgba(221,234,240,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b2d8ee', endColorstr='#ddeaf0', GradientType=1 );
}

.wg_gradient{
	background-image: url('./../img/wg_gradient.jpg')!important;
	background-repeat: repeat-x!important;
	background-position: left bottom!important;
}

body{
	font-family: $rubik;
	ul{
		list-style: none;
		margin: 0;
		padding: 0;
	}
	h1,h2,h3,h4,h5,h6,p,a,ul,ol{
		margin: 0;
		padding: 0;
		font-weight: 400;
	}
	a{
		text-decoration: none;
		&:hover{
			text-decoration: none;
		}
	}
}

div.page{
	overflow: hidden;
}

a.more1{
	font-size: 15px;
	padding: 26px 31px;
	border-radius: 50px;
	transition: 0.3s all ease;
	display: inline-block;
	text-transform: uppercase;
	font-weight: 500;
	&.blue{
		background-color: $blue;
		color: $white;
		&:hover{
			color: $blue;
			background-color: $white;
		}
	}
	&.orange{
		background-color: $orange;
		color: $blue;
		&:hover{
			color: $blue;
			background-color: $white;
		}
	}
	&.green{
		background-color: $green;
		color: $blue;
		&:hover{
			color: $blue;
			background-color: $white;
		}
	}
	&.yellow{
		background-color: $yellow;
		color: $blue;
		&:hover{
			color: $blue;
			background-color: $white;
		}
	}
	&.white{
		//background-color: #f2f2f4;
		background-color: transparent;
		border: 2px solid $yellow;
		color: #425b74;
		&:hover{
			background-color: $yellow;
			color: $white;
		}
	}
	&.white2{
		background-color: $white;
		border: 2px solid $yellow;
		color: #425b74;
		&:hover{
			background-color: $yellow;
			color: $white;
		}
	}
	&.transparent{
		background-color: transparent;
		color: #425b74;
		border:2px solid #dce4e7;
	}
}

div.default_font{
	font-size: 17px;
	line-height: 30px;
	font-family: $raleway;
	h1,h2,h3,h4,h5{
		font-family: $rubik;
	}
	h1{
		font-size: 75px;
		line-height: 85px;
	}
	h2{
		font-size: 44px;
		line-height: 54px;
	}
	h3{
		font-size: 36px;
		line-height: 46px;
	}
	h4{
		font-size: 24px;
		line-height: 34px;
	}
	h5{
		font-size: 22px;
		line-height: 32px;
	}
	h6{
		font-size: 18px;
		line-height: 30px;
	}
	p{

	}
	u{
		text-decoration: none;
	}
	table{
		width: 100%;
		tr{
			border-bottom: 2px solid #eef4f6;
			&:first-child{
				border-bottom: none;
				background-color: $yellow;
				td{
					padding: 20px;
				}
			}
			background-color: $white;
			td{
				padding: 20px;
				color: $blue;
				h1,h2,h3,h4,h5,h6,p,ul,ol,a{
					padding-bottom: 0px!important;
				}
			}
		}
	}
}

div.text_desc{
	div.default_font{
		h1,h2,h3,h4,h5,h6,p,ul,ol,a{
			padding-bottom: 30px;
			&:last-child{
				padding-bottom: 0;
			}
			a{
				color: $yellow!important;
			}
		}
		ul{
			li{
				padding-left: 18px;
				background-position: left top 15px;
				background-repeat: no-repeat;
				background-image: url('./../img/yellow_mark.png');
			}
		}
		ol{
			padding-left: 18px;
		}
	}
}

div.page{

}

header.header{
	.search{
		//padding-left: 18%;
		margin-left: -4px;
		position: relative;
		div.add{
			position: absolute;
			top: 0;
			left: 100%;
			height: 100%;
			background-color: #f4f4f4;
			display: block;
		}
		div.holder{
			width: 100%;
			background-color: #f4f4f4;
			position: relative;
			&:after{
				content: '';
				display: block;
				height: 100%;
				width: 100%;
			}
			input{
				&.input{
					width: 100%;
					height: 64px;
					border: none;
					padding-left: 64px;
					outline: none;
					font-family: $rubik;
					font-size: 16px;
					color: #4f6a85;
					font-weight: 400;
					background-color: transparent;
				}
				&.submit{
					width: 64px;
					height: 0;
					padding-bottom: 64px;
					background-repeat: no-repeat;
					background-position: center;
					background-image: url('./../img/search_icon.png');
					outline: none;
					border: none;
					position: absolute;
					top: 0;
					left: 0;
					background-color: transparent;
				}
			}
			::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			  color: #4f6a85;
			}
			::-moz-placeholder { /* Firefox 19+ */
			  color: #4f6a85;
			}
			:-ms-input-placeholder { /* IE 10+ */
			  color: #4f6a85;
			}
			:-moz-placeholder { /* Firefox 18- */
			  color: #4f6a85;
			}
		}
	}
	div.header_top{
		padding-top: 20px;
		padding-bottom: 18px;
		border-bottom: 2px solid #eef4f6;
		div.header_left{
			> div{
				display: inline-block;
				vertical-align: top;
				&.logo_box{

				}
				&.adres_box{
					margin-top: 20px;
					padding-left: 39px;
					margin-left: 39px;
					border-left: 2px solid $yellow;
					div.default_font{
						color: $font_color;
						padding: 15px 0;
						h1,h2,h3,h4,h5,h6{
							color: $blue;
						}
					}
				}
			}
		}
		div.header_right{
			padding-top: 15px;
			div.contact_box{
				margin-bottom: 15px;
				> ul{
					text-align: right;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    -ms-flex-align: center;
                    -webkit-align-items: center;
                    -webkit-box-align: center;

                    align-items: center;
					> li{
						display: inline-block;
						vertical-align: middle;
						//margin-right: 50px;
                        margin-right: 30px;
                        @media only screen and (max-width: 1200px) {
                            margin-right: 0 !important;
                        }
                        &:not(.phone_holder){
                            width: 40px;
                            height: 40px;
                            @media only screen and (max-width: 1200px) {
                                width: 28px;
                                height: 28px;
                            }
                            img{
                               height: 40px;
                                width: auto;
                                @media only screen and (max-width: 1200px) {
                                    height: 28px;
                                }
                            }
                        }
						&:last-child{
							margin-right: 0;
						}
						div{
							&.phone{
                                white-space: nowrap;
                                img{
                                    height: 40px;
                                    width: auto;
                                    @media only screen and (max-width: 1200px) {
                                        height: 28px;
                                    }
                                }
                                .default_font{
                                    h4{
                                        font-size: 18px;
                                        line-height: 30px;
                                    }
                                }
								> ul{
									> li{
										display: inline-block;
										vertical-align: middle;
										div.default_font{
											color: $blue;
										}
										&:first-child{
											padding-right: 20px;
											margin-right: 20px;
											border-right: 2px solid $yellow;
                                            @media only screen and (max-width: 767px) {
                                                padding-left: 10px;
                                                margin-right: 10px;
                                                padding-right: 10px;
                                            }
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	div.header_bottom{
		padding: 20px 0 24px 0;
		div.nav{
			padding-left: 15px;
			> div{
				display: inline-block;
				vertical-align: top;
				&.menu_box{
					ul{
						li{
							display: inline-block;
							vertical-align: top;
							padding-right: 6px;
							margin-right: 6px;
							position: relative;
							&.on{
								&:after{
									height: 0;
								}
								a{
									color: $white;
								}
							}
							&:hover{
								&:after{
									height: 0;
								}
								a{
									color: $white;
								}
							}
							&:after{
								content: '';
								display: block;
								position: absolute;
								bottom: 0;
								right: 0;
								height: 100%;
								transition: 0.3s all ease;
							}
							&:nth-child(1){
								a{
									background-color: #ffe400;
								}
								&:after{
									border-right: 2px solid #ffe400;
								}
							}
							&:nth-child(2){
								a{
									background-color: #bbe300;
								}
								&:after{
									border-right: 2px solid #bbe300;
								}
							}
							&:nth-child(3){
								a{
									background-color: #00d2ff;
								}
								&:after{
									border-right: 2px solid #00d2ff;
								}
							}
							&:nth-child(4){
								a{
									background-color: #eba7ff;
								}
								&:after{
									border-right: 2px solid #eba7ff;
								}
							}
							&:nth-child(5){
								a{
									background-color: #ff4b1b;
								}
								&:after{
									border-right: 2px solid #ff4b1b;
								}
							}
							&:nth-child(6){
								a{
									background-color: #ffc000;
								}
								&:after{
									border-right: 2px solid #ffc000;
								}
							}
							&:nth-child(7){
								a{
									background-color: #fe8f00;
								}
								&:after{
									border-right: 2px solid #fe8f00;
								}
							}
							a{
								font-family: $rubik;
								font-weight: 500;
								padding: 21px 38px;
								color: $blue;
								font-size: 18px;
								display: block;
								transition: 0.3s all ease;
							}
							&:last-child{
								margin-right: 0;
							}
						}
					}
				}
			}
		}
	}
}

section.slider{
	padding: 4% 0;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	min-height: 670px;
	position: relative;
	div.slider_content{
		position: relative;
		z-index: 5;
		min-height: 380px;
		width: 100%;
		div.text_desc{
			margin-bottom: 43px;
			div.default_font{
				color: #608bb8;
				h1,h2,h3,h4,h5,h6{
					font-weight: 500;
					color: $blue;
				}
				strong{
					color: $green;
					font-weight: 700;
				}
			}
		}
		div.buttons{
			> ul{
				> li{
					display: inline-block;
					vertical-align: middle;
					margin-right: 34px;
					div.default_font{
						color: $blue;
						p{
							font-weight: 700;
						}
					}
					&:last-child{
						margin-right: 0;
					}
				}
			}
		}
	}
	div.butterfly{
		position: absolute;
		height: 100%;
		width: 14%;
		background-position: left top 30%;
		background-repeat: no-repeat;
		background-image: url('./../img/slider_butterfly.png');
		background-size: contain;
		position: absolute;
		top: 0;
		left: 0;
	}
}

main.content{
	position: relative;
	z-index: 3;
}

section.home_about{
	@include gradient;
	overflow: hidden;
	div.hands_top{
		margin-top: -180px;
		//margin-top: -100px;
		//height: 506px;
		height: 0;
		position: relative;
		width: 100%;
		padding-bottom: 26.5%;
		> div{
			@include fill;
			background-repeat: no-repeat;
			background-position: center top;
			background-size: cover;
		}
	}
	div.hands_bottom{
		//height: 583px;
		height: 0;
		padding-bottom: 30.5%;
		width: 100%;
		position: relative;
		margin-top: -140px;
		> div{
			@include fill;
			background-repeat: no-repeat;
			background-position: center bottom;
			background-size: cover;
		}
	}
	div.text_desc{
		&.home_about_desc{
			margin-bottom: 20%;
		}
		div.default_font{
			color: $font_color;
			h1,h2,h3,h4,h5,h6{
				font-weight: 500;
				color: $blue;
				u{
					border-bottom: 3px solid $green;
					text-decoration: none;
				}
				strong{
					color: $green;
				}
			}
		}
	}
	div.infoarea{
		//margin-top: 300px;
		margin-top: 450px;
		z-index: 5;
	}
	div.info_box{
		//margin-bottom: 20%;
		position: relative;
		&:last-child{
			margin-bottom: 0;
		}
		&.ib_left{
			div.desc{
				&:after{
					width: 42px;
					height: 37px;
					content: '';
					display: block;
					background-repeat: no-repeat;
					background-position: center;
					background-image: url(./../img/triangle1.png);
					position: absolute;
					right: 100%;
					bottom: 30px;
				}
			}
		}
		&.ib_right{
			div.desc{
				&:after{
					width: 42px;
					height: 37px;
					content: '';
					display: block;
					background-repeat: no-repeat;
					background-position: center;
					background-image: url(./../img/triangle2.png);
					position: absolute;
					left: 100%;
					bottom: 30px;
				}
			}
		}
		div.image{
			position: relative;
			width: 100%;
			height: 0;
			padding-bottom: 100%;
			> div{
				@include fill;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
			}
		}
		div.desc{
			padding: 33px 50px;
			background-color: #f6f9fa;
			border-radius: 20px;
			margin-top: 60px;
			border: 2px solid $white;
			-webkit-box-shadow: 0px 0px 16px 3px rgba(196,211,214,1);
			-moz-box-shadow: 0px 0px 16px 3px rgba(196,211,214,1);
			box-shadow: 0px 0px 16px 3px rgba(196,211,214,1);
			position: relative;
		}
	}
	div.end{
		//margin-top: -200px;
		margin-top: -400px;
		position: relative;
		&:before{
			content: '';
			display: block;
			position: absolute;
			left: 150px;
			bottom: 100%;
			height: 72px;
			width: 100%;
			background-repeat: no-repeat;
			background-position:left center;
			background-image: url('./../img/lines.png');
			background-size: contain;
		}
		div.text_desc{
			padding-top: 35px;
			div.default_font{
				h1,h2,h3,h4,h5,h6{
					padding-bottom: 10px;
					&:last-child{
						padding-bottom: 0;
					}
				}
			}
		}
	}
}

div.vertical_height{
	@include fill;
	> div{
		display: table;
		width: 100%;
		height: 100%;
		> div{
			display: table-cell;
			vertical-align: middle;
			height: 100%;
		}
	}
}

section.event{
	background-image: url('./../img/events_bg2.jpg');
	background-repeat: no-repeat;
	background-position: left bottom;
	margin-top: -2%;
	padding: 50px 0 100px 0;
	position: relative;
	background-color: $blue;
	text-align: center;
	background-size: 100% auto;
	&:before{
		z-index: 10;
		position: absolute;
		bottom: 100%;
		content: '';
		display: block;
		width: 100%;
		height: 200px;
		background-position: center bottom;
		background-repeat: no-repeat;
		background-image: url('./../img/cover2.png');
		background-size: contain;
	}
	div.date{
		margin:0 auto 34px auto;
		width: 100px;
		height: 0;
		padding-bottom: 100px;
		border-radius: 50%;
		background-color: #fe8f00;
		color: $blue;
		position: relative;
		font-size: 15px;
		strong{
			font-size: 40px;
			font-weight: 500;
		}
	}
	header.event_header{
		margin-bottom: 50px;
		div.default_font{
			color: $white;
			h1,h2,h3,h4,h5,h6{
				display: inline-block;
				position: relative;
				padding: 0 16px;
				&:before{
					content: '';
					display: block;
					width: 18px;
					height: 12px;
					background-position: center;
					background-repeat: no-repeat;
					background-image: url('./../img/q1.png');
					position: absolute;
					top: 0;
					right: 100%;
				}
				&:after{
					content: '';
					display: block;
					width: 18px;
					height: 12px;
					background-position: center;
					background-repeat: no-repeat;
					background-image: url('./../img/q2.png');
					position: absolute;
					top: 0;
					left: 100%;
				}
			}
			strong{
				font-weight: 500;
			}
		}
	}
	div.desc{
		margin-bottom: 50px;
		div.text_desc{
			div.default_font{
				color: #8096ce;
			}
		}
	}
	div.cover2{
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 96px;
		background-repeat: no-repeat;
		background-position: left bottom;
		background-image: url('./../img/cover2.png');
		z-index: 10;
		display: none;
	}
	div.container{
		div.text_desc{
			position: relative;
			z-index: 5;
		}
		position: relative;
		z-index: 5;
	}
	div.green_flower{
		position: absolute;
		right: 0;
		bottom: 60px;
		width: 21%;
		height: 81%;
		background-repeat: no-repeat;
		background-position: right bottom;
		background-image: url('./../img/green_flower.png');
		background-size: contain;
	}
	div.shape{
		position: absolute;

		&.shape1{
			width: 120px;
			height: 0;
			padding-bottom: 120px;
			background-repeat: no-repeat;
			background-position: center;
			background-image: url('./../img/event_shape1.png');
			bottom: -25px;
			left: -60px;
		}
		&.shape2{
			height: 6px;
			width: 90px;
			right: 0;
			bottom: 120px;
			background-repeat: no-repeat;
			background-position: center;
			background-image: url('./../img/event_shape2.png');
		}
		&.shape3{
			top: -18px;;
			right: 10%;
			width: 135px;
			height: 67px;
			background-repeat: no-repeat;
			background-position: center;
			background-image: url('./../img/event_shape3.png');
		}
	}
	@keyframes fly{
	  0{top: 0;}
	  //50%{right: 50%; top: 40%;}
		 25% {top: 20%;}
	  50% {top: 10%; left: 100px;}
		100% {top: 0;}
	}
	div.butterfly{
		position: absolute;
		top: 0;
		width: 190px;
		height: 225px;
		animation: fly 7s infinite;
		left: 30px;
		img{
			max-width: 100%;
			height: auto;
		}
	}
	position: relative;
}

header.section_header{
	padding: 50px 0;
	div.default_font{
		text-align: center;
		color: $blue;
		strong{
			font-weight: 500;
		}
	}
}

a.more2{
	display: inline-block;
	font-size: 15px;
	font-weight: 500;
	color: #03498b;
	padding: 15px 37px;
	border: 2px solid $yellow;
	border-radius: 50px;
	transition: 0.3s all ease;
	&:hover{
		background-color: $yellow;
	}
}

section.news{
	padding-bottom: 100px;
	position: relative;
	z-index: 10;
	background-repeat: no-repeat;
	background-position: center bottom;
	//background-image: url('./../img/line5.png');
	div.news_list{
		> ul{
			> li{
				display: inline-block;
				vertical-align: top;
				width: 30%;
				margin-right: 5%;
				margin-bottom: 5%;
				&:last-child{
					margin-bottom: 0;
				}
				&:nth-child(3n){
					margin-right: -4px;
					margin-left: -4px;
				}
				article{
					div.image{
						height: 217px;
						background-repeat: no-repeat;
						background-position: center;
						background-size: cover;
						width: 100%;
						border-bottom: 2px solid $yellow;
						margin-bottom: 20px;
						position: relative;
						div.date{
							position: absolute;
							bottom: 0;
							right: 0;
							font-size: 15px;
							font-weight: 500;
							width: 105px;
							height: 0;
							padding-bottom: 65px;
							color: $white;
							background-color: #03498b;
							strong{
								font-weight: 500;
								font-size: 22px;
							}
						}
					}
					div.desc{
						div.text_desc{
							font-size: 15px;
							line-height: 24px;
							h1,h2,h3,h4,h5,h6,p,ul,ol{
								padding-bottom: 10px;
							}
							div.default_font{
								color: #03498b;
							}
						}
					}
					div.more{
						text-align: center;
					}
				}
			}
		}
		a.read_more{
			font-size: 15px;
			color: #4f6a85;
			padding-right: 15px;
			background-repeat: no-repeat;
			background-position: right center;
			background-image: url('./../img/read_more_icon.png');
		}
	}
	&.no_line{
		padding-bottom: 50px;
		background-image: none;
	}
}

a.all_click{
	@include fill;
}

section.gallery{
	div.gallery_list{
		> ul{
			> li{
				position: relative;
				display: inline-block;
				vertical-align: top;
				width: 30%;
				margin-right: 5%;
				margin-bottom: 5%;
				&:last-child{
					margin-bottom: 0;
				}
				&:nth-child(3n){
					margin-right: -4px;
					margin-left: -4px;
				}
				&:hover{
					div.image{
						div.vertical_height{
							opacity: 0;
						}
					}
				}
				div.image{
					height: 245px;
					width: 100%;
					position: relative;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
					div.vertical_height{
						background-color: rgba(15,54,149,0.7);
						opacity: 1;
						transition: 0.3s all ease;
						div.default_font{
							color: $white;
							text-transform: uppercase;
							font-weight: 500;
							font-family: $rubik;
							padding: 0 15px;
							line-height: 20px;
							p{
								position: relative;
								display: inline-block;
								padding: 0 19px;
								&:before{
									content: '';
									display: block;
									position: absolute;
									left: 0%;
									top: 0;
									height: 100%;
									width: 11px;
									background-repeat: no-repeat;
									background-position: center;
									background-size: contain;
									background-image: url('./../img/bracket1.png');
								}
								&:after{
									content: '';
									display: block;
									position: absolute;
									right: 0%;
									top: 0;
									height: 100%;
									width: 11px;
									background-repeat: no-repeat;
									background-position: center;
									background-size: contain;
									background-image: url('./../img/bracket2.png');
								}
							}
						}
					}
				}
			}
		}
	}
}

footer.footer{
	div.footer_top{
		margin-top: -17%;
		overflow: hidden;
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 22%;
		div.footer_hill1{
			position: absolute;
			left: -5%;
			height: 100%;
			width: 110%;
			background-repeat: no-repeat;
			background-position: center bottom;
			background-size: cover;
			background-image: url('./../img/hill1.png');
		}
		div.footer_hill2{
			position: absolute;
			bottom: 0;
			left: -5%;
			height: 90%;
			width: 110%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			background-image: url('./../img/hill2.png');
			div.lines1{
				position: absolute;
				top: 35%;
				left: 0;
				height: 35%;
				width: 55%;
				background-repeat: no-repeat;
				background-position: left top;
				background-image: url('./../img/lines2.png');
				background-size: cover;
			}
			div.lines2{
				position: absolute;
				bottom: 0;
				right: -5%;
				height: 35%;
				width: 55%;
				background-repeat: no-repeat;
				background-position: left top;
				background-image: url('./../img/lines3.png');
				background-size: cover;
			}
		}
		div.footer_grass{
			position: absolute;
			bottom: 0;
			left: -5%;
			height: 15.5%;
			width: 110%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			background-image: url('./../img/grass.png');
		}
	}
	div.footer_bottom{
		div.bottom_menu{
			margin-bottom: 15px;
			header{
				margin-bottom: 20px;
				div.default_font{
					color: #03498b;
				}
			}
			nav.menu2{
				ul{
					li{
						display: block;
						margin-bottom: 10px;
						a{
							font-size: 13px;
							line-height: 18px;
							color: #425b74;
							font-family: $rubik;
							transition: 0.3s all ease;
							&:hover{
								padding-left: 10px;
							}
						}
					}
				}
			}
		}
		div.image{
			width: 100%;
			height: 145px;
			background-repeat: no-repeat;
			background-position:left center;
			background-size: contain;

		}
		div.text_desc{
			padding-bottom: 30px;
			div.default_font{
				text-align: justify;
			}
		}
		padding: 30px 0;
		ul{
			> li{
				display: inline-block;

			}
			&.copyright{
				> li{
					padding-right: 40px;
					margin-right: 40px;
					border-right: 2px solid $yellow;
					padding-top: 20px;
					padding-bottom: 20px;
					vertical-align: top;
					&:last-child{
						border-right: 0;
						padding-right: 0;
						margin-right: 0;
					}
				}
				div.default_font{
					font-family: $rubik;
					color: #425b74;
					h6,p{
						text-transform: uppercase;
					}
					p{
						font-weight: 500;
						cursor: pointer;
					}
				}
			}
			&.devs{
				padding-top: 20px;
				> li{
					padding-right: 30px;
					vertical-align: top;
					div.default_font{
						font-family: $rubik;
						color: #425b74;
						font-weight: 500;
					}
					&:last-child{
						padding-right: 0;
					}
				}
			}
		}
	}
}

div.page{
	&.subpage{
		header.header{background-color: $white;}
		footer.footer div.footer_bottom{background-color: $white;}
		background-color: #f6f9fa;
		main.content{
			background-position: left top;
			background-repeat: no-repeat;
			background-size: contain;
			background-image: url('./../img/subpage_bg.png');
			//background-color: #f6f9fa;
		}
		section.gallery{
			padding-bottom: 60px;
		}
	}
}

section.top{
	height: 220px;
	width: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

section.nav_way{
	div.way{
		padding: 35px 0;
		border-bottom: 1px solid $white;
		ul{
			> li{
				display: inline-block;
				vertical-align: top;
				&:last-child{
					a{
						padding-right: 0;
						margin-right: 0;
						background-image: none;
					}
				}
				a{
				font-family: $raleway;
					color: #4f6a85;
					font-size: 14px;
					font-weight: 400;
					display: inline-block;
					padding-right: 23px;
					margin-right: 13px;
					background-repeat: no-repeat;
					background-position: right center;
					background-image: url('./../img/way_arr.png');
				}
			}
		}
	}
	.text-right{
		padding-top: 13px;
	}
}

header.section_header2{
	padding: 50px 0;
	position: relative;
	&:after{
		border-top: 3px solid $yellow;
		content: '';
		display: block;
		width: 100%;
		position: absolute;
		left: 0;
		top: 50%;
	}
	div.default_font{
		position: relative;
		z-index: 5;
		color: $blue;
		display: inline-block;
		padding-right: 60px;
		background-color: #f6f9fa;
		strong{
			font-weight: 500;
		}
	}
}

section.dinners{
	padding-bottom: 60px;
	div.image_box{
		height: 400px;
		width: 100%;
		position: relative;
		margin-bottom: 60px;
		-webkit-box-shadow: 0px 0px 49px -10px rgba(24,24,24,1);
		-moz-box-shadow: 0px 0px 49px -10px rgba(24,24,24,1);
		box-shadow: 0px 0px 49px -10px rgba(24,24,24,1);
		div.image{
			@include fill;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
		}
		div.yellow_butterfly{
			//display: block;
			width: 296px;
			height: 264px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			background-image: url('./../img/yellow_butterfly.png');
			position: absolute;
			bottom: -95px;
			right: -200px;
			z-index: -1;
		}
	}
	div.desc1{
		padding-bottom: 60px;
		border-bottom: 2px solid $white;
		div.text_desc{
			div.default_font{

				color: #425b74;
				text-align: center;
				h1,h2,h3,h4,h5,h6,p,ul,ol{
					font-family: $raleway;
					font-weight: 500;
					padding-bottom: 10px;
					strong{
						font-weight: 700;
					}
					&:last-child{
						padding-bottom: 0;
					}
				}
				u{
					border-bottom: 2px solid $yellow;
				}
			}
		}
	}
	div.desc2{
		div.text_desc{
			u{
				border-bottom:  2px solid $yellow;
			}
		}
	}
	div.dinner_plan{
		> div{
			> div{
				> ul{
					> li{
						margin-bottom: 30px;
					}
				}
			}
		}
		div.date{
			margin-bottom: 10px;
			> div{
				display: inline-block;
				vertical-align: top;
				color: #425b74;
				&.default_font{
					h1,h2,h3,h4,h5,h6,p{
					font-family: $raleway;
				}
				}
				&:last-child{
					padding-left: 23px;
					margin-left: 13px;
					position: relative;
					&:before{
						content: '/';
						font-weight: 700;
						position: absolute;
						top: 0;
						left: 0;
						height: 100%;
						width: 100%;
						font-size: 24px;
						color: $yellow;
					}
				}
			}
		}
		div.desc{
			color: #425b74;
		}
	}
}

section.bells{
	margin-bottom: 10%;
	div.table{
		width: 100%;
		> ul{
			width: 100%;
			text-align: center;
			> li{
				display: inline-block;
				vertical-align: top;
				border-right: 2px solid #ebf0f2;
				width: 25%;
				&:last-child{
					border-right: 0;
				}
				div.table_header{
					background-color: $yellow;
					padding: 24px;
					div.default_font{
						color: $blue;
						h3{
							font-family: $rubik;
							font-size: 30px;
						}
					}
				}
				div.table_content{
					background-color: $white;
					padding: 40px;
					text-align: left;
					ol{
						margin: 0;
						padding: 0;
						list-style-type: none;
						> li{
							counter-increment: step-counter;
							font-size: 20px;
 							 margin-bottom: 10px;
							font-family: $raleway;
							color: #425b74;
							font-weight: 500;
							&:last-child{
								margin-bottom: 0;
							}
							&:before{
								content: counter(step-counter)")";
								  margin-right: 5px;
								  font-size: 100%;
								  color: #0096ec;
								  font-weight: 500;
								  padding: 3px 8px;
							}
						}
					}
				}
			}
		}
		&.table_first{
			background-repeat: no-repeat;
			background-position: right center;
			background-image: url('./../img/yellow_butterfly2.png');
		}
	}
}

section.documents{
	padding-bottom: 10%;
	div.documents_list{
		> ul{
			> li{
				padding-left: 60px;
				background-position: left space;
				background-repeat: no-repeat;
				font-size: 18px;
				color: #3c5269;
				font-family: $raleway;
				font-weight: 500;
				margin-bottom: 23px;
				position: relative;
				&:last-child{
					margin-bottom: 0;
				}
				p{
					padding: 8px 0;
				}
				&.pdf{
					background-image: url('./../img/pdf_icon.png');
				}
				&.doc{
					background-image: url('./../img/doc_icon.png');
				}
			}
		}
	}
}

section.offer{
	padding-bottom: 30px;
	div.offer_holder{
		margin-bottom: 30px;
		div.desc{
			div.text_desc{
				div.default_font{
					color: #3c5269;
				}
			}
		}
	}
	div.offer_left{
		div.image{
			&:after{
				content: '';
				display: block;
				height: 100%;
				width: 100%;
				position: absolute;
				top: 0;
				right: 100%;
				background-color: $green;
			}
		}
	}
	div.offer_right{
		div.image{
			&:after{
				content: '';
				display: block;
				height: 100%;
				width: 100%;
				position: absolute;
				top: 0;
				left: 100%;
				background-color: $blue;
			}
		}
	}
	div.image{
		width: 100%;
		height: 0;
		padding-bottom: 71%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		position: relative;
	}
	div.desc{
		div.text_desc{
			div.default_font{
				h1,h2,h3,h4,h5,h6,p,ol,ul,a{
					padding-bottom: 10px;
					&:last-child{
						padding-bottom: 0;
					}
				}
			}
		}
	}
}

section.contact{
	padding-bottom: 60px;
	div.contact_data{
		div.text_desc{
			div.default_font{
				h1,h2,h3,h4,h5{
					color: #1d3d8a;
				}
				h6,p{
					color: #425b74;
				}
			}
		}
	}
	div.map{
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 85%;
		> iframe{
			@include fill;
		}
	}
}

section.news_details{
	padding-bottom: 100px;
	div.image{
		width: 100%;
		height: 0;
		padding-bottom: 71%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		position: relative;
	}
	div.desc{
		div.text_desc{
			div.default_font{
				h1,h2,h3,h4,h5,h6,p,ol,ul,a{
					color: #3c5269;
					padding-bottom: 10px;
					&:last-child{
						padding-bottom: 0;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1366px) {

}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	header.header div.header_top div.header_left > div.adres_box{padding-left: 20px; margin-left: 20px; margin-top: 25px;}
	header.header div.header_top div.header_left > div.adres_box div.default_font{padding: 5px 0;}
	header.header div.header_top div.header_right{padding-top: 25px;}
	div.logo_box img{width: 141px; height: auto;}

	header.header div.header_bottom div.nav > div.menu_box ul li a{font-size: 13px; padding: 15px 32.5px;}
	header.header div.header_bottom div.nav > div.search div.holder input.submit{padding-bottom: 45px; width: 45px;}
	header.header div.header_bottom div.nav > div.search div.holder input.input{height: 45px;}

	section.slider{min-height: 550px;}

	a.more1{font-size: 13px;}
	div.default_font{font-size: 13px; line-height: 25px;}
	div.default_font h1{font-size: 50px;}
	div.default_font h2{font-size: 36px;}
	div.default_font h3{font-size: 30px;}
	div.default_font h4{font-size: 22px;}
	div.default_font h5{font-size: 18px;}
	div.default_font h6{font-size: 15px;}

	header.header div.header_top div.header_right div.contact_box > ul > li{margin-right: 23px;}
	header.header .search div.holder input.submit{width: 45px; padding-bottom: 45px;}
	header.header .search div.holder input.input{height: 45px; font-size: 13px;}
	header.header div.phone div.default_font h4{font-size: 18px;}
	header.header div.header_top div.header_right div.contact_box{margin-bottom: 13px;}
	li.fb img{width: 45px; height: auto;}
	li.bip img{width: 45px; height: auto;}
	//header.header .search{padding-left: 26%;}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

}

@media only screen and (min-width: 320px) and (max-width: 767px) {
	section.only_text div.default_font{overflow-x: scroll;}
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
	header.header div.header_bottom div.nav > div.menu_box ul li a{font-size: 13px;}
	header.header div.header_bottom div.nav > div.search{margin-left: 0;}
	ul.nav{padding-left: 0;}
	header.header div.header_bottom div.nav > div.menu_box ul li:after{display: none;}
	header.header div.header_bottom div.nav > div.menu_box ul li{margin-right: 0; width: 100%; padding-right: 0;}
	.nav{padding-left: 15px; display: flex; flex-flow: column;}
	.nav div.search {align-items: stretch; order: 1;}
	.nav div.menu_box {align-items: stretch; order: 2;}
	.menu_box nav.menu{display: none;}
	div.search{margin-bottom: 15px;}
	nav.menu{text-align: center;}

	header.header div.header_top div.header_right{padding-top: 0px;}
	header.header div.header_top div.header_right div.contact_box > ul > li div.phone > ul > li div.default_font h4{font-size: 13px;}
	header.header div.header_top div.header_right div.contact_box > ul > li img{height: 25px; width: auto;}
	header.header div.header_top div.header_right div.contact_box > ul > li{margin-right: 20px;}

	div.logo_box{position: relative; width: 100%;}
	div.adres_box{display: none!important;}
	div.logo_box img{width: 50px; height: auto;}
	header.header div.header_bottom div.nav > div{width: 100%;}
	header.header div.header_bottom div.nav > div.search div.holder{width: 100%!important;}
	header.header div.header_bottom div.nav > div.search div.add{display: none;}

	div.rwd_button{width: 30px; position: absolute; top: 15px; right: 0;}
	div.rwd_button > div{width: 100%; height: 3px; margin-bottom: 3px;}
	div.rwd_button > div:last-child{margin-bottom: 0;}
	div.rwd_button > div:nth-child(1){background-color: $yellow;}
	div.rwd_button > div:nth-child(2){background-color: $green;}
	div.rwd_button > div:nth-child(3){background-color: $blue;}

	section.slider{min-height: 570px;}
	section.slider div.slider_content div.text_desc div.default_font{text-align: center;}
	div.buttons > ul{text-align: center;}
	section.slider div.slider_content div.buttons > ul > li{display: block; margin-left: auto; margin-right: auto; margin-bottom: 15px;}
	section.slider div.butterfly{background-position: left bottom 150px;}

	a.more1{font-size: 13px; padding: 20px 25px;}
	div.default_font{font-size: 13px; line-height: 25px;}
	div.default_font h1{font-size: 40px;}
	div.default_font h2{font-size: 32px;}
	div.default_font h3{font-size: 30px;}
	div.default_font h4{font-size: 22px;}
	div.default_font h5{font-size: 18px;}
	div.default_font h6{font-size: 15px;}

	section.home_about div.end{margin-top: -300px; margin-bottom: 100px;}
	section.home_about div.end div.text_desc div.default_font{text-align: center;}
	section.home_about div.end:before{left: 0;}
	section.home_about div.info_box div.image{width: 50%; padding-bottom: 50%; margin: 0 auto;}
	section.home_about div.hands_top{margin-bottom: 50px;}
	section.home_about div.info_box.ib_left div.desc:after{display: none;}
	section.home_about div.info_box.ib_right div.desc:after{display: none;}
	section.home_about div.info_box div.desc{padding: 20px 30px;}

	section.event header.event_header div.default_font h1:before, section.event header.event_header div.default_font h2:before, section.event header.event_header div.default_font h3:before, section.event header.event_header div.default_font h4:before, section.event header.event_header div.default_font h5:before, section.event header.event_header div.default_font h6:before{right: auto; left: 0;}
	section.event header.event_header div.default_font h1:after, section.event header.event_header div.default_font h2:after, section.event header.event_header div.default_font h3:after, section.event header.event_header div.default_font h4:after, section.event header.event_header div.default_font h5:after, section.event header.event_header div.default_font h6:after{left: auto; right: 0;}

	section.news div.news_list > ul > li{width: 100%; margin-right: 0; margin-bottom: 30px;}
	section.news div.news_list > ul > li:last-child{margin-bottom: 30px;}
	section.news div.news_list > ul > li article div.image{height: 300px;}
	section.news div.news_list > ul > li article div.desc div.text_desc div.default_font{text-align: center;}

	section.gallery div.gallery_list > ul > li{width: 100%; margin-right: 0; margin-bottom: 30px;}
	section.gallery div.gallery_list > ul > li:last-child{margin-bottom: 30px;}
	section.gallery div.gallery_list > ul > li div.image{height: 300px;}

	footer.footer div.footer_bottom ul.copyright{text-align: center;}
	footer.footer div.footer_bottom ul.copyright > li{padding-right: 0; margin-right: 0; border: none; width: 100%; padding-top: 10px; padding-bottom: 10px;}
	footer.footer div.footer_bottom ul.devs{padding-top: 0; text-align: center;}
	footer.footer div.footer_bottom ul.devs > li{padding-right: 0; padding-bottom: 10px; width: 100%;}
	footer.footer div.footer_bottom{padding: 20px 0;}

	section.bells div.table > ul > li{width: 50%;}
	section.bells div.table > ul > li div.table_header{padding: 15px;}
	section.bells div.table > ul > li div.table_header div.default_font h3{font-size: 20px;}
	section.bells div.table > ul > li div.table_content{padding: 10px;}
	section.bells div.table > ul > li div.table_content ol > li{font-size: 15px;}
	header.section_header2{padding: 30px 0;}

	header.section_header2 div.default_font{text-align: center; padding-right: 0; display: block;}
	header.section_header2{margin-bottom: 30px;}
	header.section_header2:after{top: 100%;}

	section.contact{padding-bottom: 30px;}
	div.contact_data{text-align: center; margin-bottom: 30px;}
	section.contact div.map{padding-bottom: 300px;}

	section.dinners{padding-bottom: 30px;}
	section.dinners div.image_box div.yellow_butterfly{display: none;}
	section.dinners div.desc1{padding-bottom: 0}
	header.section_header{padding: 30px 0;}
	section.dinners div.image_box{height: 300px; margin-bottom: 0;}
	div.dinner_plan{text-align: center;}
	section.dinners div.dinner_plan div.date > div{width: 100%;}
	section.dinners div.dinner_plan div.date > div:last-child{margin-left: 0; padding-left: 0; margin-top: 15px;}
	section.dinners div.dinner_plan div.date > div:last-child:before{display: none;}

	section.documents{padding-bottom: 15%;}
	section.documents div.documents_list > ul > li{padding-left: 0; padding-top: 50px; background-position: center top; text-align: center;}

	div.news_list{text-align: center;}

	section.news_details div.image{margin-bottom: 15px;}
	section.news_details{padding-bottom: 0;}

	section.offer div.image{margin-bottom: 15px;}
	section.offer div.offer_left div.image:after{display: none;}
	section.offer div.offer_right div.image:after{display: none;}
	section.offer{padding-bottom: 0;}
	div.offer_holder{display: flex; flex-flow: column;}
	div.offer_holder.offer_right div.col-md-6:first-child{order: 2; align-items: stretch;}
	div.offer_holder.offer_right div.col-md-6:last-child{order: 1; align-items: stretch;}

	header.header .search{padding-left: 0;}
	header.header .search div.holder input.submit{width: 45px; padding-bottom: 45px;}
	header.header .search div.holder input.input{height: 45px; font-size: 13px;}
	header.header div.header_bottom{padding: 0 0 0 0;}
	header.header .search{margin-bottom: 0;}
	ul.nav{padding: 20px 0;}

	footer.footer div.footer_bottom div.bottom_menu{text-align:  center;}
	footer.footer div.footer_bottom div.image{background-position: center;}
	footer.footer div.footer_bottom div.text_desc div.default_font{text-align: center;}

	section.event div.butterfly{display: none;}
}

section.only_text{
	padding-bottom: 50px;
}
